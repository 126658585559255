@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Condensed', 'san-serif';

}

body {
    overflow-x: hidden;
    background-color: #23262e;
}

.bg {
    background-color: #23262e;
}

.nav-bg {
    background-color: rgba(38, 42, 51, 1);
}

input[type="text"] {
    padding-left: 10px;
}

.banner {
    background-image: url('./asset/banner.png');
    background-size: cover;
    background-position: 50% 20%;
    max-height: 100%;

}

.filter {
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
}

.gameItems {
    font-style: italic;
    cursor: pointer;
}

.gameItems:hover {

    opacity: 0.3;
}

.gameItems-active {
    border: 2px solid rgb(109, 216, 101);
    opacity: 0.6;
}

.search {
    cursor: pointer;
}

.loadingImage {

    width: 20px;
}